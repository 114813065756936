import React from 'react'
import { graphql, Link } from 'gatsby'

import WorkContainer from '../../components/WorkContainer'
import Layout from '../../components/Layout'
import { ProjectTitle, EnglishOnly } from '../../components/Text'
import Metadata from '../../components/Metadata'
import CustomerLogo from '../../components/CustomerLogo'
import ProjectImage from '../../components/ProjectImage'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function NegotiateChange({ data }) {
  const { t } = useTranslation()

  const project = data.projectsJson

  return (
    <Layout>
      <Metadata title={t(project.title)} isArticle />
      <WorkContainer>
        <CustomerLogo project={project} />
        <ProjectTitle title={t(project.title)} />
        <EnglishOnly />
        <ProjectImage data={project.gallery.main} top />
        <h2>Need</h2>
        <p>
          <a
            href="https://www.bloomberg.com/account/newsletters/money-stuff"
            target="_blank"
            rel="noreferrer"
          >
            Matt Levine from Bloomberg
          </a>{' '}
          has the story{' '}
          <a
            href="https://www.bloomberg.com/opinion/articles/2023-10-23/nobody-wants-mutual-funds-now#:~:text=Brightline%20redline"
            target="_blank"
            rel="noreferrer"
          >
            <strong>Brightline redline</strong>
          </a>{' '}
          about how Investment Bank Morgan Stanley forgot to turn on{' '}
          <i>Track Changes</i> in MS Word during contract negotiation, resulting
          in a{' '}
          <a
            href="https://assets.bwbx.io/documents/users/iqjWHBFdfxIU/rIQN4H_LmWTk/v0"
            target="_blank"
            rel="noreferrer"
          >
            lawsuit
          </a>{' '}
          over 750 million US dollars:
        </p>
        <p className="m-4">
          <i>
            Yet when Morgan Stanley's outside counsel returned a purportedly
            “fully executed” version of the amendment [...], the supposedly
            fully executed version included important language in the new
            section 2.11(h) that Knighthead and Certares had not before seen and
            had not agreed to.
          </i>
        </p>
        <p>
          The alternative, he suggests, could be reading the whole contract
          aloud to everyone before signing it.
        </p>
        <p>
          However, that doesn't touch on the underlying problem: Tools like
          Track Changes or Google's <i>Suggest Mode</i> are not designed for
          actual negotiation:
          <ul>
            <li>
              It's impractical to use Track Changes to negotiate between two
              alternative sentences, with each party successively refining
              theirs until a shared agreement is reached.
            </li>
            <li>
              If a section is both moved and edited, Track Changes will hide any
              edits made within that section. (Pro-tip for Morgan Stanleys
              outside counsel: Leaving Track Changes on but moving the entire
              bullet point down by one will also conceal your changes.)
            </li>
            <li>
              There is no way to solicit tentative or private feedback: every
              change is visible to all.
            </li>
          </ul>
          This list doesn't even account for issues with document versioning or
          write permissions.
        </p>
        <p>
          As a result, Track Changes is unreliable for negotiating contracts.
        </p>
        <blockquote>Collaboration is the ability to negotiate</blockquote>
        <h2>Approach</h2>
        <p>
          To effectively enable negotiation within a shared document,
          contributors need the freedom to write without concern for existing
          tracked changes. The step to identify differences and reach agreement
          should be separate and effortless.
        </p>
        <p>
          We have developed a web application to improve collaboration through
          two key design changes. For the tech-savvy, it resembles how{' '}
          <strong>git</strong> works:
          <ul>
            <li>
              Every contributor makes edits without having to see Track Changes,
              thanks to being assigned their own <strong>document copy</strong>{' '}
              under the hood.
            </li>
            <li>
              We then make edits comprehensible to others by{' '}
              <strong>calculating actual changes</strong>, instead of tracking
              edit history as "Track Changes" does.
            </li>
          </ul>
        </p>
        <blockquote>
          <strike>Track</strike>
          <u>Understand</u> changes
        </blockquote>
        <p>
          Our custom algorithm enhances the comprehension of differences between
          contributors by presenting all modifications, even major ones, as
          intuitive, atomic suggestions. These can be individually accepted,
          rejected, or modified, facilitating negotiation and reaching
          agreement.
        </p>
        <p>
          The algorithm is described on our{' '}
          <Link to="../treediff">Tree diff page</Link>.
        </p>
        <p>
          The resulting "understandable changes" look very similar to "Track
          Changes", but we learn a new hint which "Track Changes" cannot
          produce: greyed-out sections indicate moved-away sections.
        </p>
        <p>Lets look at an example:</p>
        <ProjectImage data={project.gallery.trackUnderstand} />
        <p>
          <i>
            <small>
              Our application captures both sentence movement and word
              replacement, which both "Track Changes" and "Suggest" fail to
              recognize.
            </small>
          </i>
        </p>
        <p>
          The algorithm detects two changes: A sentence move, and a word
          replacement. Both can be separately accepted or rejected.
        </p>
        <p>
          In comparison, "Track Changes" or "Suggest" offer only editing
          history: A sentence removed, a sentence added, but no indication that
          it is the same. Discovering this manually becomes exceedingly
          difficult on meaningful changes.
        </p>
        <h2>Benefits</h2>
        <p>There are important benefits to this collaboration approach:</p>
        <p>
          <ul>
            <li>
              Understand changes of any complexity and by multiple contributors
            </li>
            <li>
              Freely and concurrently edit the document, without having to
              navigate around other contributors' redactions
            </li>
            <li>
              Negotiating change and finding compromise become a seamless part
              of the editing experience
            </li>
            <li>
              Increase the number of contributors without losing any clarity on
              competing changes
            </li>
            <li>Simplify document permissions management</li>
            <li>Automatic versioning and audit trail</li>
            <li>Novel workflows such as requesting private edits</li>
          </ul>
        </p>
        <p>
          It might have saved Morgan Stanley millions of dollars, along with a
          few hours of reading contracts aloud to everyone.
        </p>
        <h2>Current state</h2>
        <p>
          Early versions of the web application and the algorithm are currently
          being evaluated by a small number of businesses.
        </p>
      </WorkContainer>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    projectsJson(name: { eq: "negotiateChange" }) {
      name
      title
      customer
      customerLogo {
        childImageSharp {
          gatsbyImageData
        }
      }
      tags
      link
      gallery {
        main {
          alt
          src {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        trackUnderstand {
          alt
          src {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        ideacomp_feedback {
          alt
          src {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
